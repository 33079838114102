import React from 'react';
// import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Keycloak from 'keycloak-js'

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

//keycloak init options (comment out below & uncomment App rendering above to turn off Keycloak)
let initOptions = {
  url: process.env.REACT_APP_KEYCLOAK_CONFIG_URL,
  realm:process.env.REACT_APP_KEYCLOAK_CONFIG_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CONFIG_CLIENT_ID,
  "ssl-required":process.env.REACT_APP_KEYCLOAK_CONFIG_SSL_REQUIRED,
  "public-client":process.env.REACT_APP_KEYCLOAK_CONFIG_PUBLIC_CLIENT,
  "confidential-port":process.env.REACT_APP_KEYCLOAK_CONFIG_CONFIDENTIAL_PORT,
  onLoad: process.env.REACT_APP_KEYCLOAK_CONFIG_ON_LOAD,
}

let keycloak = Keycloak(initOptions);

keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {

  if (!auth) {
      window.location.reload();
  } else {
      console.info("Authenticated");

      keycloak.loadUserInfo().then(userData => {
        //React Render
        ReactDOM.render(
          <App userInfo={userData} />,
          document.getElementById('root')
        );
      
        localStorage.setItem("react-token", keycloak.token);
        localStorage.setItem("react-refresh-token", keycloak.refreshToken);
      
        setTimeout(() => {
            keycloak.updateToken(70).then((refreshed) => {
                if (refreshed) {
                    console.debug('Token refreshed' + refreshed);
                } else {
                    console.warn('Token not refreshed, valid for '
                        + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
                }
            }).catch(() => {
                console.error('Failed to refresh token');
            });

        }, 60000)
      })
  }

}).catch(() => {
  console.error("Authentication Failed");
});