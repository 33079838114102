import logo from './logo.svg';
import './App.css';

function App(props) {
  return (
    <div className="App">
      <header className="App-header">
        
      </header>
      <div className="App-body">
        <div id="header">
          {props.userInfo?props.userInfo.preferred_username:null}&nbsp;&nbsp;|&nbsp;&nbsp;
          <a href="https://auth.silubi.io/realms/silubi/protocol/openid-connect/logout?post_logout_redirect_uri=https://www.silubi.io&client_id=silubi-ui">Logout</a> &nbsp;&nbsp;
        </div>

        <div id="content">
          <img src={logo} className="silubi-logo" alt="Silubi&trade;" />
        </div>

        <div id="footer">
            &copy; {new Date().getFullYear()} <a href="https://www.silubi.com" target="new">Silubi&trade;</a> <span className="wide-break">&nbsp;&nbsp;</span><span className="narrow-break"><br/></span> <a href="https://www.worldreservetrust.com/legal-notice/" target="new">Legal Notice</a> | <a href="https://www.worldreservetrust.com/privacy-policy/" target="new">Privacy</a> | <a href="https://www.worldreservetrust.com/cookie-policy/" target="new">Cookie Policy</a>
            <span className="wide-break">&nbsp;&nbsp;</span><span className="narrow-break"><br/></span><a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer"> | Learn React</a>
        </div>
      </div>
    </div>
  );
}

export default App;